import TextField from '@mui/material/TextField';
import { skipToken } from '@reduxjs/toolkit/query';
import { getDisplayName, useSearchUserListQuery } from '@top-solution/microtecnica-utils';
import { type Ref, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Equipment } from '../../../entities/Equipment';
import { useReadEquipmentListQuery } from '../../../services/equipmentApi';
import { EquipmentAutocomplete, type EquipmentAutocompleteProps } from '../../inputs/EquipmentAutocomplete';

export type EquipmentAutocompleteWithAdditionalFieldsProps = Omit<EquipmentAutocompleteProps, 'value' | 'onChange'> & {
  value: Equipment;
  onChange: (value: Equipment | null) => void;
};

export function EquipmentAutocompleteWithAdditionalFieldsComponent(
  props: EquipmentAutocompleteWithAdditionalFieldsProps,
  ref: Ref<unknown>,
) {
  const { value, onChange, ...autocompleteProps } = props;
  const { t } = useTranslation();

  const readEquipmentList = useReadEquipmentListQuery();

  const usernames = useMemo(() => {
    const usernames = new Set<string>();
    if (value) {
      usernames.add(value.manager);
      usernames.add(value.valueStreamManager);
    }
    return Array.from(usernames);
  }, [value]);

  const searchUserList = useSearchUserListQuery(usernames.length ? { usernames } : skipToken);

  const { manager, valueStreamManager } = useMemo(
    () => ({
      manager: searchUserList.data?.find(({ username }) => username === value?.manager) ?? null,
      valueStreamManager: searchUserList.data?.find(({ username }) => username === value?.valueStreamManager) ?? null,
    }),
    [value, searchUserList.data],
  );

  return (
    <>
      <EquipmentAutocomplete
        className="field-equipment"
        label={t('equipment.field.equipment_code')}
        value={value?.id ?? ''}
        onChange={(_, value) => onChange(readEquipmentList.data?.find((equipment) => equipment.id === value) ?? null)}
        {...autocompleteProps}
        ref={ref}
      />
      <TextField
        className="field-aircraft"
        label={t('equipment.field.aircraft')}
        value={value?.aircraft ?? ''}
        disabled
      />
      <TextField
        className="field-value_stream"
        label={t('equipment.field.value_stream')}
        value={value?.valueStream ?? ''}
        disabled
      />
      <TextField
        className="field-manager"
        label={t('equipment.field.manager')}
        value={manager ? getDisplayName(manager) : ''}
        disabled
      />
      <TextField
        className="field-value_stream_manager"
        label={t('equipment.field.value_stream_manager')}
        value={valueStreamManager ? getDisplayName(valueStreamManager) : ''}
        disabled
      />
    </>
  );
}

export const EquipmentAutocompleteWithAdditionalFields = forwardRef(EquipmentAutocompleteWithAdditionalFieldsComponent);
