import { getDisplayName, useLazySearchUserListQuery, useReadPlantsQuery } from '@top-solution/microtecnica-utils';
import { Workbook } from 'exceljs';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../entities/ECR';
import { useReadDepartmentListQuery } from '../services/departmentApi';
import { useReadDocumentTypeListQuery } from '../services/documentTypeApi';
import { useReadPriorityListQuery } from '../services/priorityApi';
import { useReadRequestTypeListQuery } from '../services/requestTypeApi';

export function useExportExcel(): {
  exportData: (data: ECR[]) => Promise<void>;
  inProgress: boolean;
  isReady: boolean;
} {
  const { t } = useTranslation();

  const readDocumentType = useReadDocumentTypeListQuery();
  const readRequestType = useReadRequestTypeListQuery();
  const readPriority = useReadPriorityListQuery();
  const readPlant = useReadPlantsQuery();
  const readDepartment = useReadDepartmentListQuery();

  const [searchUsers] = useLazySearchUserListQuery();

  const [inProgress, setInProgress] = useState(false);
  const isReady = useMemo(
    () => !(readDocumentType.isLoading && readRequestType.isLoading && readPriority.isLoading),
    [readDocumentType.isLoading, readPriority.isLoading, readRequestType.isLoading],
  );

  const exportData = useCallback(
    async (data: ECR[]) => {
      setInProgress(true);
      const departmentMap = new Map(readDepartment.data?.map(({ id, name }) => [id, name]));
      const documentTypeMap = new Map(readDocumentType.data?.map(({ id, name }) => [id, name]));
      const requestTypeMap = new Map(readRequestType.data?.map(({ id, name }) => [id, name]));
      const priorityMap = new Map(readPriority.data?.map(({ id, name }) => [id, name]));
      const userMap = new Map<string, string>();
      for (const ecr of data) {
        userMap.set(ecr.openBy, ecr.openBy);
        if (ecr.reportedBy) {
          userMap.set(ecr.reportedBy, ecr.reportedBy);
        }
        for (const analysis of ecr.analyses ?? []) {
          userMap.set(analysis.equipment.manager, analysis.equipment.manager);
        }
      }

      try {
        const data = await searchUsers({ usernames: Array.from(userMap.values()) }).unwrap();
        for (const user of data) {
          userMap.set(user.username, getDisplayName(user));
        }
      } catch (error) {
        console.error('Failed to fetch users', error);
      }

      const workbook = new Workbook();
      workbook.title = `ECR ${new Date().toISOString()}`;
      workbook.creator = import.meta.env.VITE_APP_ID;
      workbook.created = new Date();

      const worksheet = workbook.addWorksheet(t('pages.ecr_list.title'));
      worksheet.properties.defaultColWidth = 20;

      let lastRow = 1;

      const row1 = worksheet.getRow(lastRow++);
      row1.font = { bold: true };
      row1.values = [
        t('ecr.field.id'),
        t('ecr.field.status'),
        t('ecr.field.analysis_one'),
        t('ecr.field.plant'),
        t('ecr.field.department'),
        t('ecr.field.reported_by'),
        t('ecr.field.document_id'),
        t('ecr.field.document_revision'),
        t('ecr.field.document_type'),
        t('ecr.field.request_type'),
        t('ecr.field.priority'),
        t('ecr.field.open_by'),
        t('ecr.field.open_at'),
        t('equipment.field.equipment_code'),
        t('equipment.field.manager'),
        t('equipment.field.value_stream'),
        t('ecr.field.assigned_at'),
        t('equipment.field.accepted'),
        t('equipment.field.analyzed_at'),
        t('ecr.field.change_proposal'),
        t('ecr.field.closed_at'),
        t('ecr.field.suspended_at'),
        t('ecr.field.status_comments'),
      ];

      for (const ecr of data) {
        const analyses = ecr.analyses ?? [null];

        for (let i = 0; i < analyses.length; i++) {
          const analysis = analyses[i];
          const row = worksheet.getRow(lastRow++);

          row.values = [
            ecr.id,
            t(`status.${ecr.status}`),
            analysis ? i + 1 : '',
            readPlant.data?.map[ecr.plantId.toString()]?.name ?? ecr.plantId,
            departmentMap.has(ecr.departmentId)
              ? t(`department.${departmentMap.get(ecr.departmentId)}`)
              : ecr.departmentId,
            ecr.reportedBy && userMap.get(ecr.reportedBy),
            ecr.documentId,
            ecr.documentRevision,
            documentTypeMap.has(ecr.documentTypeId)
              ? t(`document_type.${documentTypeMap.get(ecr.documentTypeId)}`)
              : ecr.documentTypeId,
            requestTypeMap.has(ecr.requestTypeId)
              ? t(`request_type.${requestTypeMap.get(ecr.requestTypeId)}`)
              : ecr.requestTypeId,
            priorityMap.has(ecr.priorityId) ? t(`priority.${priorityMap.get(ecr.priorityId)}`) : ecr.priorityId,
            userMap.get(ecr.openBy),
            ecr.openAt && new Date(ecr.openAt),
            analysis?.equipment.id,
            analysis?.equipment.manager && userMap.get(analysis.equipment.manager),
            analysis?.equipment.valueStream,
            ecr.assignedAt && new Date(ecr.assignedAt),
            analysis?.accepted === true
              ? t('status.accepted')
              : analysis?.accepted === false
                ? t('status.rejected')
                : undefined,
            analysis?.analyzedAt && new Date(analysis.analyzedAt),
            ecr.changeProposal,
            ecr.closedAt && new Date(ecr.closedAt),
            ecr.suspendedAt && new Date(ecr.suspendedAt),
            ecr.statusComments,
          ];
        }
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${workbook.title}.xlsx`;
      link.click();
      setInProgress(false);
    },
    [
      readDepartment.data,
      readDocumentType.data,
      readPlant.data,
      readPriority.data,
      readRequestType.data,
      searchUsers,
      t,
    ],
  );

  return { exportData, inProgress, isReady };
}
