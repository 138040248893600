import Button from '@mui/material/Button';
import { type SxProps, type Theme, styled } from '@mui/material/styles';
import { PlantLabel, UserLabel } from '@top-solution/microtecnica-mui';
import { formatDateTime } from '@top-solution/microtecnica-utils';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../../entities/ECR';
import { StatusChip } from '../StatusChip';
import { DepartmentLabel } from '../fields/DepartmentLabel';
import { DocumentTypeLabel } from '../fields/DocumentTypeLabel';
import { PriorityLabel } from '../fields/PriorityLabel';
import { RequestTypeLabel } from '../fields/RequestTypeLabel';
import { ECRDataWrapper } from './ECRDataWrapper';
import { ECRField } from './ECRField';

const FieldList = styled('dl')(({ theme }) => ({
  '.ECRField': {
    [['&.id', '&.status', '&.plant', '&.department'].join(',')]: {
      flex: '1 1 20%',
    },
    [['&.document_id', '&.document_revision', '&.document_type', '&.safety_part'].join(',')]: {
      flex: '1 1 25%',
    },
    [['&.reported_by', '&.request_type', '&.priority'].join(',')]: {
      flex: '1 1 33.33%',
    },
    [[
      '&.open_by',
      '&.open_at',
      '&.assigned_at',
      '&.analysed_at',
      '&.closed_at',
      '&.suspended_at',
      '&.deleted_at',
      '&.deleted_by',
    ].join(',')]: {
      flex: '1 1 50%',
    },
    '&.attachment_list': {
      dd: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: theme.spacing(0.25),
      },
    },
  },
}));

type ECRDataProps = {
  ecr: ECR;
  sx?: SxProps<Theme>;
};

export function ECRData(props: ECRDataProps): JSX.Element {
  const { ecr, sx } = props;
  const { t } = useTranslation();

  return (
    <ECRDataWrapper sx={sx}>
      <FieldList>
        <ECRField field={'id'}>{ecr.id}</ECRField>
        <ECRField field={'status'}>
          <StatusChip status={ecr.status} size="small" />
        </ECRField>
        <ECRField field={'plant'}>
          <PlantLabel plantId={ecr.plantId} />
        </ECRField>
        <ECRField field={'department'}>
          <DepartmentLabel departmentId={ecr.departmentId} />
        </ECRField>
        <ECRField field={'open_by'}>
          <UserLabel username={ecr.openBy} />
        </ECRField>
        <ECRField field={'open_at'}>{formatDateTime(ecr.openAt)}</ECRField>
        <ECRField field={'assigned_at'}>{ecr.assignedAt && formatDateTime(ecr.assignedAt)}</ECRField>
        <ECRField field={'analysed_at'}>{ecr.analysedAt && formatDateTime(ecr.analysedAt)}</ECRField>
        <ECRField field={'closed_at'}>{ecr.closedAt && formatDateTime(ecr.closedAt)}</ECRField>
        <ECRField field={'suspended_at'}>{ecr.suspendedAt && formatDateTime(ecr.suspendedAt)}</ECRField>
        {ecr.deletedAt && <ECRField field={'deleted_at'}>{formatDateTime(ecr.deletedAt)}</ECRField>}
        {ecr.deletedBy && (
          <ECRField field={'deleted_by'}>
            <UserLabel username={ecr.deletedBy} />
          </ECRField>
        )}
        <ECRField field={'document_id'}>{ecr.documentId}</ECRField>
        <ECRField field={'document_revision'}>{ecr.documentRevision}</ECRField>
        <ECRField field={'document_type'}>
          <DocumentTypeLabel documentTypeId={ecr.documentTypeId} />
        </ECRField>
        <ECRField field={'safety_part'}>{ecr.safetyPart ? t('yes') : t('no')}</ECRField>
        <ECRField field={'reported_by'}>{ecr.reportedBy && <UserLabel username={ecr.reportedBy} />}</ECRField>
        <ECRField field={'request_type'}>
          <RequestTypeLabel requestTypeId={ecr.requestTypeId} />
        </ECRField>
        <ECRField field={'priority'}>
          <PriorityLabel priorityId={ecr.priorityId} />
        </ECRField>
        <ECRField field={'reason'}>{ecr.reason}</ECRField>
        <ECRField field={'request'}>{ecr.request}</ECRField>
        {ecr.attachmentList?.length ? (
          <ECRField field={'attachment_list'}>
            {ecr.attachmentList?.map((attachment) => (
              <Button
                size="small"
                variant="outlined"
                component="a"
                key={attachment.id}
                href={`/attachments${attachment.path}`}
                download={attachment.name}
              >
                {attachment.name}
              </Button>
            ))}
          </ECRField>
        ) : null}
        {ecr.preAnalysis && (
          <>
            <ECRField field={'pre_analysis_rejected'}>{ecr.preAnalysis.accepted ? t('no') : t('yes')}</ECRField>
            {ecr.preAnalysis?.reason && <ECRField field={'pre_analysis_reason'}>{ecr.preAnalysis.reason}</ECRField>}
          </>
        )}
      </FieldList>
    </ECRDataWrapper>
  );
}
