import Autocomplete, { type AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import { type Ref, forwardRef, useMemo } from 'react';
import type { Equipment } from '../../entities/Equipment';
import { useReadEquipmentListQuery } from '../../services/equipmentApi';

export type EquipmentAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<Equipment['id'], Multiple, DisableClearable, FreeSolo>,
  'options' | 'renderInput' | 'onInputChange'
> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required' | 'sx'>;

function EquipmentAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: EquipmentAutocompleteProps<Multiple, DisableClearable, FreeSolo>, ref: Ref<unknown>): JSX.Element {
  const { label, error, helperText, variant, required, sx, ...autocompleteProps } = props;
  const { data, isLoading, error: loadingError } = useReadEquipmentListQuery();

  const options = useMemo(
    () => data?.filter(({ deletedAt }) => deletedAt === undefined).map(({ id }) => id) || [],
    [data],
  );

  return (
    <Autocomplete
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(loadingError) || error}
          helperText={(loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined}
          required={required}
          sx={sx}
        />
      )}
      ref={ref}
      loading={isLoading}
      {...autocompleteProps}
    />
  );
}

export const EquipmentAutocomplete = forwardRef<unknown, EquipmentAutocompleteProps>(EquipmentAutocompleteComponent);
