import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import type { ComponentProps } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { type ECRAnalysis, ECRAnalysisSchema, MAX_ANALYSES } from '../../entities/ECRAnalysis';
import type { ECRAssignment } from '../../entities/ECRAssignment';
import type { Equipment } from '../../entities/Equipment';
import { ECRAnalyseFormMode } from './ECRAnalyseFormMode';
import { AnalysisFieldset } from './fields/AnalysisFieldset';
import { PreAnalysisFieldset } from './fields/PreAnalysisFieldset';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),

  '.remove-analysis': {
    flex: '0 0 0%',
  },

  [theme.breakpoints.up('lg')]: {
    '.field-pre_rejected': {
      flex: '0 0 200px',
    },

    '.field-equipment_code, .field-value_stream, .field-aircraft': {
      flex: '1 0 30%',
    },

    '.field-manager, .field-value_stream_manager': {
      flex: '1 0 40%',
    },
  },

  '.analyses-wrapper': {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: theme.spacing(2),

    '& > fieldset': {
      flex: '1 0 100%',
      position: 'relative',
    },

    '.remove-equipment': {
      flex: '0 0 0%',
      position: 'absolute',
      top: theme.spacing(-3.75),
      right: 0,
      background: theme.palette.background.paper,
    },
  },
}));

type OnSubmitCallback = (data: ECRAnalysis) => void;

type ECRAnalyseFormProps = Omit<ComponentProps<'form'>, 'onSubmit' | 'children'> & {
  data: ECR;
  mode: ECRAnalyseFormMode;
} & (
    | {
        onSubmit: OnSubmitCallback;
        disabled?: boolean;
      }
    | {
        onSubmit?: OnSubmitCallback;
        disabled: true;
      }
  );

export function ECRAnalyseForm(props: ECRAnalyseFormProps): JSX.Element {
  const { id, mode, data, onSubmit, disabled } = props;
  const { t } = useTranslation();

  const form = useForm<ECRAnalysis>({
    defaultValues: {
      preAnalysis: {
        accepted: data.preAnalysis?.accepted ?? true,
        reason: data.preAnalysis?.reason ?? null,
      },
      analyses:
        data.analyses?.map((analysis) => ({
          ...analysis,
          assessment: analysis.assessment ?? null,
          analyzedAt: analysis.analyzedAt ?? null,
          accepted: analysis.accepted ?? null,
        })) ?? [],
    } as ECRAssignment,
    resolver: zodResolver(ECRAnalysisSchema),
  });
  const { control, handleSubmit, setValue, formState } = form;

  const accepted = useWatch({ name: 'preAnalysis.accepted', control });
  const analyses = useWatch({ name: 'analyses', control });

  const analysesError = formState.errors.analyses?.root ?? formState.errors.analyses;

  return (
    <StyledForm onSubmit={onSubmit && handleSubmit(onSubmit, console.error)} id={id}>
      <PreAnalysisFieldset
        form={form}
        className="pre_analysis-wrapper"
        disabled={disabled || (data.analyses?.length ?? 0) > 0}
      />
      {accepted && (
        <div className="analyses-wrapper">
          {analyses.map((analysis, index) => (
            <AnalysisFieldset
              key={`analysis-${index}-${analysis.id}`}
              form={form}
              analysis={analysis}
              index={index}
              mode={mode}
              disabled={disabled}
            />
          ))}
          {analyses.length < MAX_ANALYSES && mode === ECRAnalyseFormMode.ASSIGNMENT && (
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() =>
                setValue('analyses', [
                  ...analyses,
                  {
                    equipment: null as unknown as Equipment,
                    assessment: null as unknown as string,
                    analyzedAt: null,
                    accepted: null as unknown as boolean,
                  },
                ])
              }
            >
              {t('equipment.action.create')}
            </Button>
          )}

          {analysesError && (
            <Typography variant="caption" color="error" className="analyses-error">
              {analysesError.message}
            </Typography>
          )}
        </div>
      )}
    </StyledForm>
  );
}
