import LoadingButton from '@mui/lab/LoadingButton';
import { type GridColDef, type GridFilterModel, GridLogicOperator, type GridSortModel } from '@mui/x-data-grid-premium';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  DataGrid,
  type DataGridProps,
  DataGridWrapper,
  IconButton,
  actionsColDef,
  dateColDef,
  muiFiltersToPagedRequestFilters,
  usePagination,
  usePlantColDef,
  useUserColDef,
} from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { useDepartmentColDef, usePriorityColDef, useRequestTypeColDef, useStatusColDef } from '../components/DataGrid';
import { useDocumentTypeColDef } from '../components/DataGrid';
import { useTaskColDef } from '../components/DataGrid/useTaskColDef';
import {
  ECRAnalyseDialog,
  ECRAssignDialog,
  ECRChangeStatusDialog,
  ECRClearStatusDialog,
  ECRCreateDialog,
  ECRDetailsDialog,
  ECRRemoveDialog,
  ECRReopenDialog,
  ECRUpdateDialog,
} from '../components/ECR';
import {
  AnalyseIcon,
  AssignIcon,
  ChangeStatusIcon,
  CreateIcon,
  DetailsIcon,
  ExportIcon,
  RemoveIcon,
  ReopenIcon,
  UpdateIcon,
} from '../components/Icons';
import { Layout } from '../components/Layout';
import { type ECR, ECRStatus } from '../entities/ECR';
import { useACL } from '../hooks/useACL';
import { useExportExcel } from '../hooks/useExportExcel';
import { useReadECRListQuery, useReadECRQuery } from '../services/ecrApi';
import { UnauthorizedPage } from './UnauthorizedPage';

const initialState: DataGridProps['initialState'] = {
  columns: {
    columnVisibilityModel: {
      deletedBy: false,
      deletedAt: false,
      request: false,
      reason: false,
      preAnalysis_accepted: false,
      preAnalysis_reason: false,
    },
  },
};

export function ECRListPage(): JSX.Element {
  const { t } = useTranslation();

  const [ECRCreateDialogOpen, setECRCreateDialogOpen] = useState(false);
  const [selectedECR, setSelectedECR] = useState<ECR | null>(null);
  const [ECRDetailsDialogOpen, setECRDetailsDialogOpen] = useState(false);
  const [ECRUpdateDialogOpen, setECRUpdateDialogOpen] = useState(false);
  const [ECRRemoveDialogOpen, setECRRemoveDialogOpen] = useState(false);
  const [ECRReopenDialogOpen, setECRReopenDialogOpen] = useState(false);
  const [ECRAssignDialogOpen, setECRAssignDialogOpen] = useState(false);
  const [ECRAnalyseDialogOpen, setECRAnalyseDialogOpen] = useState(false);
  const [ECRChangeStatusDialogOpen, setECRChangeStatusDialogOpen] = useState(false);
  const [ECRClearStatusDialogOpen, setECRClearStatusDialogOpen] = useState(false);

  const acl = useACL();

  const match = useMatch('ecr/:id');
  const matchedId = useMemo(() => {
    if (match?.params?.id) {
      const parsedId = Number.parseInt(match.params.id, 10);
      if (Number.isInteger(parsedId)) {
        return parsedId;
      }
    }
    return null;
  }, [match]);

  const readECR = useReadECRQuery(matchedId ?? skipToken);

  useEffect(() => {
    if (readECR.data?.id === matchedId) {
      const ecr = readECR.data;
      setSelectedECR(ecr);
      if (ecr.status === ECRStatus.OPEN && acl.canAssignECR(ecr)) {
        setECRAssignDialogOpen(true);
      } else if (ecr.status === ECRStatus.ASSIGNED && acl.canAnalyseECR(ecr)) {
        setECRAnalyseDialogOpen(true);
      } else if (ecr.status === ECRStatus.ACCEPTED && acl.canChangeECRStatus(ecr)) {
        setECRChangeStatusDialogOpen(true);
      } else {
        setECRDetailsDialogOpen(true);
      }
    }
  }, [matchedId, readECR.data, acl]);

  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'openAt', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: 'status',
        operator: 'not',
        value: ECRStatus.DELETED,
      },
    ],
    logicOperator: GridLogicOperator.And,
  });

  const readECRListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel.page, paginationModel.pageSize, sortModel],
  );

  const readECRList = useReadECRListQuery(readECRListParams);

  const usernames = useMemo<string[]>(() => {
    if (readECRList.data) {
      const usernames = new Set<string>();
      for (const ecr of readECRList.data.data) {
        if (ecr.reportedBy) {
          usernames.add(ecr.reportedBy);
        }
        usernames.add(ecr.openBy);
      }
      return Array.from(usernames);
    }
    return [];
  }, [readECRList.data]);

  const userColumn = useUserColDef(usernames);
  const plantColumn = usePlantColDef();
  const priorityColumn = usePriorityColDef();
  const statusColumn = useStatusColDef();
  const taskColumn = useTaskColDef();
  const departmentColumn = useDepartmentColDef();
  const requestTypeColumn = useRequestTypeColDef();
  const documentTypeColumn = useDocumentTypeColDef();

  const columns = useMemo<GridColDef<ECR>[]>(
    () => [
      { field: 'id', headerName: t('ecr.field.id'), width: 60 },
      statusColumn,
      priorityColumn,
      taskColumn,
      { ...userColumn, field: 'reportedBy', headerName: t('ecr.field.reported_by') },
      { ...userColumn, field: 'openBy', headerName: t('ecr.field.open_by') },
      plantColumn,
      departmentColumn,
      { field: 'documentId', headerName: t('ecr.field.document_id'), width: 120 },
      { field: 'documentRevision', headerName: t('ecr.field.document_revision'), width: 120 },
      documentTypeColumn,
      { field: 'safetyPart', type: 'boolean', headerName: t('ecr.field.safety_part'), width: 120 },
      requestTypeColumn,
      { field: 'request', headerName: t('ecr.field.request'), width: 200 },
      { field: 'reason', headerName: t('ecr.field.reason'), width: 200 },
      {
        field: 'preAnalysis_accepted',
        headerName: t('ecr.field.pre_analysis_accepted'),
        type: 'boolean',
        width: 100,
        valueGetter: (_, row) => row.preAnalysis?.accepted,
      },
      {
        field: 'preAnalysis_reason',
        headerName: t('ecr.field.pre_analysis_reason'),
        width: 200,
        valueGetter: (_, row) => row.preAnalysis?.reason,
      },
      { field: 'changeProposal', headerName: t('ecr.field.change_proposal'), width: 120 },
      { ...dateColDef, field: 'openAt', headerName: t('ecr.field.open_at') },
      { ...dateColDef, field: 'assignedAt', headerName: t('ecr.field.assigned_at') },
      { ...dateColDef, field: 'analysedAt', headerName: t('ecr.field.analysed_at') },
      { ...dateColDef, field: 'closedAt', headerName: t('ecr.field.closed_at') },
      { ...dateColDef, field: 'suspendedAt', headerName: t('ecr.field.suspended_at') },
      { ...dateColDef, field: 'deletedAt', headerName: t('ecr.field.deleted_at') },
      { ...userColumn, field: 'deletedBy', headerName: t('ecr.field.deleted_by') },
      {
        ...actionsColDef,
        width: 190,
        align: 'left',
        renderHeader: () =>
          acl.canAddECR && (
            <IconButton
              size="small"
              color="primary"
              title={t('ecr.action.create')}
              onClick={() => setECRCreateDialogOpen(true)}
            >
              <CreateIcon />
            </IconButton>
          ),
        renderCell: ({ row }) => (
          <>
            <IconButton
              size="small"
              title={t('ecr.action.display')}
              onClick={() => {
                setSelectedECR(row);
                setECRDetailsDialogOpen(true);
              }}
            >
              <DetailsIcon />
            </IconButton>
            {acl.canAssignECR(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.assign')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRAssignDialogOpen(true);
                }}
              >
                <AssignIcon />
              </IconButton>
            )}
            {acl.canReopenECR(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.reopen')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRReopenDialogOpen(true);
                }}
              >
                <ReopenIcon />
              </IconButton>
            )}
            {acl.canAnalyseECR(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.analyse')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRAnalyseDialogOpen(true);
                }}
              >
                <AnalyseIcon />
              </IconButton>
            )}
            {acl.canUpdateECR(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.update')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRUpdateDialogOpen(true);
                }}
              >
                <UpdateIcon />
              </IconButton>
            )}
            {acl.canDeleteECR(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.remove')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRRemoveDialogOpen(true);
                }}
              >
                <RemoveIcon />
              </IconButton>
            )}
            {acl.canChangeECRStatus(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.change_status')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRChangeStatusDialogOpen(true);
                }}
                sx={{ marginInlineStart: 'auto' }}
              >
                <ChangeStatusIcon />
              </IconButton>
            )}
            {acl.canClearECRStatus(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.clear_status')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRClearStatusDialogOpen(true);
                }}
                sx={{ marginInlineStart: 'auto' }}
              >
                <ChangeStatusIcon />
              </IconButton>
            )}
          </>
        ),
      },
    ],
    [
      acl,
      departmentColumn,
      documentTypeColumn,
      plantColumn,
      priorityColumn,
      requestTypeColumn,
      statusColumn,
      t,
      taskColumn,
      userColumn,
    ],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const exportExcel = useExportExcel();

  const slotProps = useMemo<DataGridProps['slotProps']>(
    () => ({
      columnsManagement: {
        getTogglableColumns: (columns) =>
          columns.filter((column) => column.field !== actionsColDef.field).map((column) => column.field),
      },
      toolbar: {
        csvOptions: { disableToolbarButton: true },
        excelOptions: { disableToolbarButton: true },
      },
      footer: {
        customButtons: [
          <LoadingButton
            size="small"
            key="export"
            startIcon={<ExportIcon />}
            onClick={() => exportExcel.exportData(readECRList.data?.data ?? [])}
            disabled={!exportExcel.isReady}
            loading={exportExcel.inProgress}
          >
            Export
          </LoadingButton>,
        ],
      },
    }),
    [exportExcel, readECRList.data],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} appId={import.meta.env.VITE_APP_ID}>
      <Layout disableGutters maxWidth={false} inProgress={readECRList.isLoading} error={readECRList.error}>
        <DataGridWrapper>
          <DataGrid
            rows={readECRList.data?.data ?? []}
            rowCount={readECRList.data?.total ?? 0}
            columns={columns}
            sessionStorageId="ECRListPageDataGrid"
            loading={readECRList.isFetching}
            onRefresh={readECRList.refetch}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
            initialState={initialState}
            slotProps={slotProps}
          />
        </DataGridWrapper>
        <ECRCreateDialog open={ECRCreateDialogOpen} onClose={() => setECRCreateDialogOpen(false)} />
        {selectedECR && (
          <>
            <ECRDetailsDialog
              open={ECRDetailsDialogOpen}
              onClose={() => setECRDetailsDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRUpdateDialog
              open={ECRUpdateDialogOpen}
              onClose={() => setECRUpdateDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRRemoveDialog
              open={ECRRemoveDialogOpen}
              onClose={() => setECRRemoveDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRAssignDialog
              open={ECRAssignDialogOpen}
              onClose={() => setECRAssignDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRAnalyseDialog
              open={ECRAnalyseDialogOpen}
              onClose={() => setECRAnalyseDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRReopenDialog
              open={ECRReopenDialogOpen}
              onClose={() => setECRReopenDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRChangeStatusDialog
              open={ECRChangeStatusDialogOpen}
              onClose={() => setECRChangeStatusDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRClearStatusDialog
              open={ECRClearStatusDialogOpen}
              onClose={() => setECRClearStatusDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
          </>
        )}
      </Layout>
    </AuthGuard>
  );
}
