import { z } from 'zod';
import { EquipmentSchema } from './Equipment';
import { DateTimeSchema } from './utils';

export const AnalysisSchema = z.object({
  id: z.number(),
  equipment: EquipmentSchema,
  assessment: z.string().max(4000).optional(),
  analyzedAt: DateTimeSchema.optional(),
  accepted: z.boolean().optional(),
});

export type Analysis = z.infer<typeof AnalysisSchema>;
