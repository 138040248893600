import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  type PagedRequestParams,
  type PagedResponse,
  createPagedResponseSchema,
  formatQueryParams,
} from '@top-solution/microtecnica-utils';
import type { ECR, ECRForm } from '../entities/ECR';
import { ECRSchema } from '../entities/ECR';
import type { ECRAnalysis } from '../entities/ECRAnalysis';
import type { ECRAssignment } from '../entities/ECRAssignment';
import type { ECRChangeStatus } from '../entities/ECRChangeStatus';
import { TAG_TYPES, api } from './baseApi';

const url = 'v1/ecr';

function invalidatesTags(_result: unknown, error: FetchBaseQueryError | undefined, args: Pick<ECR, 'id'>) {
  if (error) {
    return [];
  }
  const tags = [{ type: TAG_TYPES.ECR, id: 'LIST' }];
  if (args?.id) {
    tags.push({ type: TAG_TYPES.ECR, id: args.id.toString() });
  }
  return tags;
}

function ecrToBody(ecr: ECRForm): unknown {
  return {
    ...ecr,
    attachmentList: ecr.attachmentList?.map(({ id }) => id) ?? [],
  };
}

const ecrApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readECRList: builder.query<PagedResponse<ECR>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(ECRSchema).parse(data),
      providesTags: [{ type: TAG_TYPES.ECR, id: 'LIST' }],
    }),
    readECR: builder.query<ECR, ECR['id']>({
      query: (id) => ({ url: `${url}/${id}` }),
      transformResponse: (data) => ECRSchema.parse(data),
      providesTags: (_, error, id) => (error ? [] : [{ type: TAG_TYPES.ECR, id }]),
      keepUnusedDataFor: 5,
    }),
    createECR: builder.mutation<void, ECRForm>({
      query: (ecr) => ({ url, method: 'POST', body: ecrToBody(ecr) }),
      invalidatesTags: [{ type: TAG_TYPES.ECR, id: 'LIST' }],
    }),
    updateECR: builder.mutation<void, Pick<ECR, 'id'> & ECRForm>({
      query: ({ id, ...ecr }) => ({ url: `${url}/${id}`, method: 'PUT', body: ecrToBody(ecr) }),
      invalidatesTags,
    }),
    removeECR: builder.mutation<void, Pick<ECR, 'id'>>({
      query: ({ id }) => ({ url: `${url}/${id}`, method: 'DELETE' }),
      invalidatesTags,
    }),
    reopenECR: builder.mutation<void, Pick<ECR, 'id'>>({
      query: ({ id }) => ({ url: `${url}/${id}/assignment`, method: 'DELETE' }),
      invalidatesTags,
    }),
    assignECR: builder.mutation<void, Pick<ECR, 'id'> & ECRAssignment>({
      query: ({ id, ...body }) => ({
        url: `${url}/${id}/assignment`,
        method: 'PUT',
        body,
      }),
      invalidatesTags,
    }),
    analyseECR: builder.mutation<void, Pick<ECR, 'id'> & Pick<ECRAnalysis, 'analyses'>>({
      query: ({ id, ...body }) => ({
        url: `${url}/${id}/analysis`,
        method: 'PUT',
        body,
      }),
      invalidatesTags,
    }),
    changeECRStatus: builder.mutation<void, Pick<ECR, 'id'> & ECRChangeStatus>({
      query: ({ id, ...body }) => ({ url: `${url}/${id}/change-status`, method: 'PUT', body }),
      invalidatesTags,
    }),
    clearECRStatus: builder.mutation<void, Pick<ECR, 'id'>>({
      query: ({ id }) => ({ url: `${url}/${id}/change-status`, method: 'DELETE' }),
      invalidatesTags,
    }),
  }),
  overrideExisting: false,
});

export const {
  useReadECRListQuery,
  useReadECRQuery,
  useCreateECRMutation,
  useUpdateECRMutation,
  useRemoveECRMutation,
  useReopenECRMutation,
  useAssignECRMutation,
  useAnalyseECRMutation,
  useChangeECRStatusMutation,
  useClearECRStatusMutation,
} = ecrApi;
