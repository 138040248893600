import type { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { useReadDocumentTypeListQuery } from '../../services/documentTypeApi';

export function useDocumentTypeColDef(): GridSingleSelectColDef<ECR, ECR['documentTypeId'], string> {
  const { data, isLoading } = useReadDocumentTypeListQuery();
  const { t } = useTranslation();

  return useMemo(
    () => ({
      field: 'documentTypeId',
      headerName: t('ecr.field.document_type'),
      type: 'singleSelect',
      width: 150,
      valueOptions: data?.map(({ id, name }) => ({ value: id, label: t(`document_type.${name}`) })) ?? [],
      filterable: !isLoading,
    }),
    [data, isLoading, t],
  );
}
