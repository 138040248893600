import { z } from 'zod';
import { DateTimeSchema } from './utils';

export const EquipmentSchema = z.object({
  id: z.string(),
  aircraft: z.string(),
  manager: z.string(),
  valueStream: z.string(),
  valueStreamManager: z.string(),
  deletedAt: DateTimeSchema.optional(),
});

export type Equipment = z.infer<typeof EquipmentSchema>;
