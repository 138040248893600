import { Chip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Priority } from '../../../entities/Priority';
import { useReadPriorityListQuery } from '../../../services/priorityApi';

type PriorityLabelProps = {
  priorityId: Priority['id'];
};

export function PriorityLabel({ priorityId }: PriorityLabelProps): ReactNode {
  const { t } = useTranslation();
  const readPriorityList = useReadPriorityListQuery();

  const priority = useMemo(
    () => readPriorityList.data?.find((priority) => priority.id === priorityId),
    [priorityId, readPriorityList.data],
  );

  const urgent = useMemo(() => readPriorityList.data?.find(({ value }) => value === 1000), [readPriorityList.data]);

  if (readPriorityList.isLoading) {
    return <Skeleton variant="text" width="10ch" />;
  }
  if (priority && urgent && priority.id === urgent.id) {
    return <Chip color="error" size="small" label={t(`priority.${priority.name}`)} />;
  }

  return t(`priority.${priority?.name ?? priorityId}`);
}
