import { z } from 'zod';
import { ECRStatus } from './ECR';

export const ECRChangeStatusSchema = z
  .object({
    status: z.union([z.literal(ECRStatus.CLOSED), z.literal(ECRStatus.SUSPENDED)]),
    statusComments: z.string().max(4000).nullable(),
    changeProposal: z.string().max(255).nullable(),
  })
  .superRefine((data, ctx) => {
    if (data.status === ECRStatus.SUSPENDED && !data.statusComments) {
      ctx.addIssue({
        path: ['statusComments'],
        code: z.ZodIssueCode.too_small,
        type: 'string',
        inclusive: true,
        minimum: 3,
      });
    }
    if (data.status === ECRStatus.CLOSED && !data.changeProposal) {
      ctx.addIssue({
        path: ['changeProposal'],
        code: z.ZodIssueCode.too_small,
        type: 'string',
        inclusive: true,
        minimum: 3,
      });
    }
  });

export type ECRChangeStatus = z.infer<typeof ECRChangeStatusSchema>;
