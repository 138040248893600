import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import type { ECRAssignment } from '../../entities/ECRAssignment';
import { useAssignECRMutation } from '../../services/ecrApi';
import { ECRAnalyseForm } from './ECRAnalyseForm';
import { ECRAnalyseFormMode } from './ECRAnalyseFormMode';
import { ECRData } from './ECRData';

const formId = 'ecr-assign-form';

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(2),
  },

  '& > *': {
    flex: 1,
  },
}));

type ECRAssignDialogProps = Omit<DialogProps, 'onClose'> & {
  ecr: ECR;
  onClose: () => void;
};

export function ECRAssignDialog(props: ECRAssignDialogProps): JSX.Element {
  const { ecr, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const [assign, assignStatus] = useAssignECRMutation();

  const handleSubmit = useCallback(
    async (data: ECRAssignment) => {
      await assign({
        id: ecr.id,
        preAnalysis: {
          ...data.preAnalysis,
          reason: data.preAnalysis.reason || null, // avoid sending empty string
        },
        analyses: data.preAnalysis.accepted ? data.analyses : [],
      }).unwrap();
      onClose();
    },
    [assign, ecr, onClose],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          assignStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>{t('ecr.action.assign')}</DialogTitle>
      <DialogContent>
        <FormWrapper>
          <ECRData ecr={ecr} sx={{ marginBlockStart: 1.5 }} />
          <ECRAnalyseForm id={formId} data={ecr} onSubmit={handleSubmit} mode={ECRAnalyseFormMode.ASSIGNMENT} />
        </FormWrapper>

        {assignStatus.error && <ErrorAlert error={assignStatus.error} sx={{ marginTop: 1 }} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          {t('action.cancel')}
        </Button>
        <LoadingButton color="primary" variant="contained" type="submit" form={formId} loading={assignStatus.isLoading}>
          {t('action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
