import { AuthCallback } from '@top-solution/microtecnica-utils';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { AdminRouter, ECRListPage, NotFoundPage, UnauthorizedPage } from './pages';

export function App(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<Navigate to="ecr" replace />} />
      <Route path="ecr/*" element={<ECRListPage />} />
      <Route path="admin/*" element={<AdminRouter />} />
      <Route
        path="auth"
        element={
          <AuthCallback
            onAuthenticationSuccess={navigate}
            ErrorComponent={(props) => <UnauthorizedPage description={props.error} />}
          />
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
