import Tooltip from '@mui/material/Tooltip';
import type { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { useAuth } from '@top-solution/microtecnica-utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type ECR, ECRStatus } from '../../entities/ECR';
import { AlertIcon, CloseCircleIcon, HelpIcon } from '../Icons';

enum TaskStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  ToDo = 'todo',
}

export function useTaskColDef(): GridSingleSelectColDef<ECR> {
  const { t } = useTranslation();
  const { username } = useAuth();

  return useMemo(
    () => ({
      field: 'task',
      headerName: t('ecr.field.task'),
      type: 'singleSelect',
      width: 100,
      display: 'flex',
      sortable: false,
      valueOptions: [TaskStatus.ToDo, TaskStatus.Accepted, TaskStatus.Rejected].map((value) => ({
        value,
        label: t(`task.${value}`),
      })),
      valueGetter: (_, row) => {
        if (row.status === ECRStatus.ASSIGNED || row.status === ECRStatus.ACCEPTED) {
          let assigned = 0;
          let accepted = 0;
          let rejected = 0;
          for (const analysis of row.analyses ?? []) {
            if (analysis.equipment.manager === username) {
              assigned++;
              if (analysis.accepted === true) {
                accepted++;
              } else if (analysis.accepted === false) {
                rejected++;
              }
            }
          }
          if (assigned > 0) {
            if (assigned === rejected) {
              return TaskStatus.Rejected;
            }
            if (accepted > 0 && accepted + rejected === assigned) {
              return TaskStatus.Accepted;
            }
            return TaskStatus.ToDo;
          }
        }
      },
      renderCell: ({ value }) =>
        value === TaskStatus.Accepted ? (
          <Tooltip title={t(`task.${value}`)}>
            <AlertIcon color="success" />
          </Tooltip>
        ) : value === TaskStatus.Rejected ? (
          <Tooltip title={t(`task.${value}`)}>
            <CloseCircleIcon color="error" />
          </Tooltip>
        ) : value === TaskStatus.ToDo ? (
          <Tooltip title={t(`task.${value}`)}>
            <HelpIcon color="warning" />
          </Tooltip>
        ) : (
          value
        ),
    }),
    [t, username],
  );
}
