import { type SxProps, type Theme, styled } from '@mui/material/styles';
import { UserLabel } from '@top-solution/microtecnica-mui';
import { formatDateTime } from '@top-solution/microtecnica-utils';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../../entities/ECR';
import { ECRDataWrapper } from './ECRDataWrapper';
import { ECRField } from './ECRField';

const AnalysisWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 0),

  h5: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  '.ECRField': {
    [['&.equipment_code', '&.aircraft', '&.value_stream'].join(',')]: {
      flex: '1 1 30%',
    },
    [['&.manager', '&.value_stream_manager', '&.accepted', '&.analyzed_at'].join(',')]: {
      flex: '1 1 50%',
    },
  },
}));

type ECRAnalysisProps = {
  ecr: ECR;
  sx?: SxProps<Theme>;
};

export function ECRAnalysis(props: ECRAnalysisProps): JSX.Element {
  const { ecr, sx } = props;
  const { t } = useTranslation();

  return (
    <ECRDataWrapper sx={sx}>
      {ecr.analyses?.map((analysis, index) => (
        <AnalysisWrapper key={analysis.id}>
          <h5>{t('ecr.field.analysis_index', { index: index + 1 })}</h5>
          <dl>
            <ECRField field={'equipment_code'} context="equipment.field">
              {analysis.equipment.id}
            </ECRField>
            <ECRField field={'aircraft'} context="equipment.field">
              {analysis.equipment.aircraft}
            </ECRField>
            <ECRField field={'value_stream'} context="equipment.field">
              {analysis.equipment.valueStream}
            </ECRField>
            <ECRField field={'manager'} context="equipment.field">
              <UserLabel username={analysis.equipment.manager} />
            </ECRField>
            <ECRField field={'value_stream_manager'} context="equipment.field">
              <UserLabel username={analysis.equipment.valueStreamManager} />
            </ECRField>
            <ECRField field={'accepted'} context="equipment.field">
              {analysis.accepted === true
                ? t('status.accepted')
                : analysis.accepted === false
                  ? t('status.rejected')
                  : undefined}
            </ECRField>
            <ECRField field={'analyzed_at'} context="equipment.field">
              {analysis.analyzedAt ? formatDateTime(analysis.analyzedAt) : undefined}
            </ECRField>
            <ECRField field={'assessment'} context="equipment.field">
              {analysis.assessment}
            </ECRField>
          </dl>
        </AnalysisWrapper>
      ))}
      {(ecr.changeProposal || ecr.statusComments) && (
        <AnalysisWrapper>
          <h5>{t('ecr.section.change_status')}</h5>
          <dl>
            {ecr.changeProposal && <ECRField field={'change_proposal'}>{ecr.changeProposal}</ECRField>}
            {ecr.statusComments && <ECRField field={'status_comments'}>{ecr.statusComments}</ECRField>}
          </dl>
        </AnalysisWrapper>
      )}
    </ECRDataWrapper>
  );
}
